<script lang="ts" setup>
import SkrButton from '@/components/button/SkrButton.vue'
import BaseDialog from '@/components/dialogs/BaseDialog.vue'
import ResponsiveImage from '@/components/ResponsiveImage.vue'

defineProps<{
  loading: boolean
  businessName: string
}>()

const emit = defineEmits<{
  (event: 'close'): void
  (event: 'action', data: Dialogs['DialogAcceptTerms']['contextData']): void
}>()

const { t } = useI18n()

const accept = () => {
  emit('action', {})
}
</script>

<template>
  <base-dialog max-width="640" persistent :title="t('gtc.scenario_3.title')" @close="emit('close')">
    <v-row class="py-10">
      <v-col cols="12" sm="7">
        <div>
          <strong class="d-block">{{ t('dialog.accept_terms.gtc.text.accept') }}</strong>
          <a :href="t('global.legal.gtc_link')" target="_blank" rel="noopener">{{
            t('dialog.accept_terms.gtc.link_text')
          }}</a>
          <p class="my-4">{{ t('dialog.accept_terms.gtc.text.consent') }}</p>
        </div>
        <skr-button size="xl" block :loading="loading" @click="accept">
          {{ t('global.accept') }}
        </skr-button>
      </v-col>
      <v-col v-if="$vuetify.display.smAndUp" sm="5">
        <responsive-image source="gtc-dialog" :width="142" :height="225" />
      </v-col>
    </v-row>
    <template #actions>
      <v-spacer />
    </template>
  </base-dialog>
</template>
